import React, { useState, useRef, useEffect, useCallback } from "react";
import { FormikProvider, useFormik } from "formik";
import axios from "axios";
import styles from "./style.module.scss";
import { ToastContainer, toast } from "react-toastify";
import { contactFormInitialValues } from "../../utils/formik/initialvalue";
import { contactFormValidationSchema } from "../../utils/formik/validationSchema";
import { normalizeInput } from "../../utils/helpers";
import { Button, ErrorText } from "../../components/styledComponent";
import { POST_API_URLS, BASE_URL, GET_API_URLS } from "../../utils/apiConstant";
import { SearchableSelect } from "../../components/ui";

const ContactForm = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uploadName, setUploadName] = useState("Upload File");
  const formikRef = useRef(null);
  const formik = useFormik({
    initialValues: contactFormInitialValues,
    validationSchema: contactFormValidationSchema,
    onSubmit: (values) =>
      submitHandler({
        ...values,
        phone: `+1${values.phone.replace(/[^\d]/g, "")}`,
        related_to_store: values?.related_to_store?.id,
      }),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  async function submitHandler(data) {
    props.loader(true);
    let formData = new FormData();
    for (let k in data) {
      formData.append(k, data[k]);
    }
    try {
      const apiResponse = await axios.post(
        `${BASE_URL + POST_API_URLS.CONTACT_FORM}`,
        formData
      );
      console.log("contact form response", apiResponse);
      props.loader(false);
      props.success(true);
      // return { response: apiResponse.data, error: false };
    } catch (error) {
      props.loader(false);
      console.log("contact form error", error);
      if (!error?.response) {
        return toast.error("No Server Response", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        });
      } else if (error.response?.status === 400) {
        console.log("400", error.response.data);
        // errorMsg = { status: 400, errorData: error.response.data };
        return Object.keys(error.response.data).map((k) =>
          error.response.data[k].map((val) =>
            toast.error(val, {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              style: { fontSize: "1.4rem", fontWeight: 500 },
            })
          )
        );
      } else {
        return toast.error("Response not submitted!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          style: { fontSize: "1.6rem", fontWeight: 500 },
        });
      }
    }
  }

  const apiFetch = useCallback(async (query) => {
    try {
      const endpoint = query
        ? `${BASE_URL + GET_API_URLS.GET_LOCATION}?search=${encodeURIComponent(
            query
          )}`
        : `${BASE_URL + GET_API_URLS.GET_LOCATION}`;
      const apiResponse = await axios.get(endpoint);
      // console.log(apiResponse)
      const data = apiResponse?.data;
      return data.map((item) => ({
        id: item.id,
        label: item.name,
        address: item.address,
      }));
    } catch (err) {
      console.error(err);
      return [];
    }
  }, []); // <--- empty array or put relevant dependencies here

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.fieldContainer}>
          <div className={styles.formSubjectField}>
            <label htmlFor="subject">Subject:</label>
            <select
              {...formik.getFieldProps("subject")}
              // defaultValue={"General"}
              style={
                formik.touched.subject && formik.errors.subject
                  ? { borderColor: "red" }
                  : null
              }
            >
              <option value={""} disabled={true}>
                Subject
              </option>
              <option value={"Canco Cash Loyalty / Mobile App"}>
                Canco Cash Loyalty / Mobile App
              </option>
              <option value={"Fuel"}>Fuel</option>
              <option value={"Suggestions"}>Suggestions</option>
              <option value={"General"}>General</option>
              <option value={"Donations"}>Donations</option>
              <option value={"Other Technical Issues"}>
                Other Technical Issues{" "}
              </option>
            </select>
            {formik.touched.subject && formik.errors.subject && (
              <ErrorText className={styles.errorText}>
                {formik.errors.subject}
              </ErrorText>
            )}
          </div>
        </div>
        {formik.values.subject === "Canco Cash Loyalty / Mobile App" && (
          <div className={styles.fieldContainer}>
            <div>
              <input
                type="text"
                {...formik.getFieldProps("cardnumber")}
                placeholder="Card Number*"
                style={
                  formik.touched.cardnumber && formik.errors.cardnumber
                    ? { borderColor: "red" }
                    : null
                }
              />
              {formik.touched.cardnumber && formik.errors.cardnumber && (
                <ErrorText className={styles.errorText}>
                  {formik.errors.cardnumber}
                </ErrorText>
              )}
            </div>
          </div>
        )}
        <div className={styles.fieldContainer}>
          <div>
            <input
              type="text"
              {...formik.getFieldProps("name")}
              placeholder="Full Name*"
              style={
                formik.touched.name && formik.errors.name
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.name && formik.errors.name && (
              <ErrorText className={styles.errorText}>
                {formik.errors.name}
              </ErrorText>
            )}
          </div>
          <div>
            <input
              type="text"
              name="phone"
              onChange={(e) => {
                setPhoneNumber((prev) => normalizeInput(e.target.value, prev));
              }}
              placeholder="Phone"
              value={phoneNumber}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone && (
              <ErrorText className={styles.errorText}>
                {formik.errors.phone}
              </ErrorText>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <input
              type="email"
              {...formik.getFieldProps("email")}
              placeholder="Email Address*"
              style={
                formik.touched.email && formik.errors.email
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.email && formik.errors.email && (
              <ErrorText className={styles.errorText}>
                {formik.errors.email}
              </ErrorText>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <SearchableSelect
            name="related_to_store"
            apiFetch={apiFetch}
            placeholder="Location"
          />
        </div>
        <div className={styles.fieldContainer}>
          <div>
            <textarea
              {...formik.getFieldProps("message")}
              placeholder="Message*"
              rows={8}
              style={
                formik.touched.message && formik.errors.message
                  ? { borderColor: "red" }
                  : null
              }
            />
            {formik.touched.message && formik.errors.message && (
              <ErrorText className={styles.errorText}>
                {formik.errors.message}
              </ErrorText>
            )}
          </div>
        </div>
        <div>
          <div>
            <div className="uploadLogoContainer uploadVendorFile">
              <div className="uploadContainer">
                <input
                  type="file"
                  name="attachment"
                  // hidden
                  onChange={(e) => {
                    setUploadName(e.target.files[0].name);
                    formik.setFieldValue(
                      "attachment",
                      e.currentTarget.files[0]
                    );
                  }}
                  onBlur={formik.handleBlur("attachment")}
                />

                <label htmlFor="actual-btn">UPLOAD</label>
                <span id={`fileChosen choosenFile`}>{uploadName}</span>
              </div>
            </div>
            {formik.touched.attachment && formik.errors.attachment && (
              <ErrorText className={styles.errorText}>
                {formik.errors.attachment}
              </ErrorText>
            )}
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <Button
            className={
              !(formik.isValid && formik.dirty) ? "disabledBtn" : "submitBtn"
            }
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </form>
    </FormikProvider>
  );
};

export default ContactForm;
