import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { editUserProfileValidationSchema } from "../../../../utils/formik/validationSchema";
import {
  Form,
  Input,
  Label,
  ErrorText,
  Button,
} from "../../../styledComponent";
import styles from "./style.module.scss";
import { GET_API_URLS } from "../../../../utils/apiConstant";
import { useAuth } from "../../../../context/AuthProvider";
import { normalizeInput } from "../../../../utils/helpers";
import { editUserProfileInitialValues } from "../../../../utils/formik/initialvalue";
import apiInterceptor from "../../../../api/apiCall";
import { toast, ToastContainer } from "react-toastify";
import { ToolTip } from "../../../ui";
import { routes } from "../../../../utils/routes";

const EditUserProfileForm = (props) => {
  const { userData, user, latestUserDetailHandler } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: userData ? { ...userData } : editUserProfileInitialValues,
    enableReinitialize: true,
    validationSchema: editUserProfileValidationSchema,
    onSubmit: (values) => {
      formSubmitHandler(values);
    },
  });

  async function formSubmitHandler(data) {
    props.loader(true);
    const updatedDataObj = {};
    const keys = Object.keys(userData);
    keys.map((key) => {
      if (userData[key] !== data[key]) {
        if (key === "phone") {
          return (updatedDataObj[key] = `+1${data[key].replace(/[^\d]/g, "")}`);
        }
        if (key === "dob") {
          if (data[key] === "") {
            return (updatedDataObj[key] = null);
          } else {
            return (updatedDataObj[key] = data[key]);
          }
        }
        return (updatedDataObj[key] = data[key]);
      }
      return false;
    });
    apiInterceptor
      .patch(GET_API_URLS.GET_USER_DETAIL + `${user.id}/`, {
        ...updatedDataObj,
      })
      .then((response) => {
        props.loader(false);
        navigate(`/${routes.THANK_YOU}`);
        response.status === 200 && latestUserDetailHandler();
      })
      .catch((error) => {
        props.loader(false);
        // console.log("Error", error);
        if (error.response.status === 400) {
          Object.keys(error.response.data).map((k) =>
            error.response.data[k].map((val) =>
              toast.error(val, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
                style: { fontSize: "1.4rem", fontWeight: 500 },
              })
            )
          );
        } else {
          toast.error("Update Profile Failed!", {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            style: { fontSize: "1.4rem", fontWeight: 500 },
          });
        }
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      formikRef.current = formik;
      formikRef.current.setFieldValue("phone", phoneNumber);
    }, 1000);

    return () => clearTimeout(timer);
  }, [phoneNumber, formik]);

  useEffect(() => {
    if (userData) {
      userData?.phone &&
        setPhoneNumber((prev) => normalizeInput(userData?.phone, prev));
    }
  }, [userData]);

  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        className={styles.becomeFleetMemberForm}
      >
        {userData && (
          <>
            <div>
              <div>
                <Label htmlFor="first_name">First Name*</Label>
                <Input
                  type="text"
                  {...formik.getFieldProps("first_name")}
                  style={
                    formik.touched.first_name &&
                    formik.errors.first_name && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.first_name}
                  </ErrorText>
                )}
              </div>
              <div>
                <Label htmlFor="last_name">Last Name*</Label>
                <Input
                  type="text"
                  {...formik.getFieldProps("last_name")}
                  style={
                    formik.touched.last_name &&
                    formik.errors.last_name && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.last_name}
                  </ErrorText>
                )}
              </div>
            </div>
            <div>
              <div>
                <div className={styles.toolTipLabel}>
                  <Label htmlFor="email">Email*</Label>
                  <ToolTip />
                </div>
                <Input
                  type="email"
                  {...formik.getFieldProps("email")}
                  style={
                    formik.touched.email &&
                    formik.errors.email && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                  disabled
                />
                {formik.touched.email && formik.errors.email && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.email}
                  </ErrorText>
                )}
              </div>
            </div>
            <div>
              <div>
                <Label htmlFor="address">Address</Label>
                <Input
                  type="text"
                  {...formik.getFieldProps("address")}
                  style={
                    formik.touched.address &&
                    formik.errors.address && {
                      boxShadow: "0px 0px 0px 2px red",
                    }
                  }
                />
                {formik.touched.address && formik.errors.address && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.address}
                  </ErrorText>
                )}
              </div>
            </div>
            <div>
              <div>
                <Label>DOB</Label>
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  {...formik.getFieldProps("dob")}
                  disabled
                  style={
                    formik.touched.dob &&
                    formik.errors.dob && { boxShadow: "0px 0px 0px 2px red" }
                  }
                />
                {formik.touched.dob && formik.errors.dob && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.dob}
                  </ErrorText>
                )}
              </div>
            </div>
            <div>
              <div>
                <Label htmlFor="phone">Phone*</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  // disabled
                  placeholder="(xxx) xxx-xxxx"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    // console.log("change hua");
                    setPhoneNumber((prev) =>
                      normalizeInput(e.target.value, prev)
                    );
                  }}
                  value={phoneNumber}
                  style={
                    formik.touched.phone &&
                    formik.errors.phone && { boxShadow: "0px 0px 0px 2px red" }
                  }
                />
                {formik.touched.phone && formik.errors.phone && (
                  <ErrorText className={styles.errorText}>
                    {formik.errors.phone}
                  </ErrorText>
                )}
              </div>
            </div>
            <div className={styles.formBtnContainer}>
              <Button
                type="button"
                secondary
                onClick={() => navigate(routes.LOGIN)}
              >
                Cancel
              </Button>
              <Button type="submit">Update</Button>
            </div>
          </>
        )}
      </Form>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
};

export default EditUserProfileForm;
