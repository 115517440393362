// SearchableSelect.jsx
import React, { useState, useEffect, useRef } from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, ListGroup } from "react-bootstrap";
import styles from "./style.module.scss";

/**
 * props:
 *  - name (string): Formik field name
 *  - apiFetch (function): async function(query) => returns [{id, label, ...}, ...]
 *  - label (string): Label for this field
 *  - placeholder (string): Placeholder text for the input
 */
function SearchableSelect({ name, apiFetch, label, placeholder }) {
  const [meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  // local state for the search input, dropdown options, and whether dropdown is open
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // ref to detect clicks outside this component
  const containerRef = useRef(null);

  // --- Fetch data whenever inputValue changes ---
  useEffect(() => {
    let isMounted = true;
    apiFetch(inputValue)
      .then((data) => {
        if (isMounted) {
          setOptions(data);
        }
      })
      .catch((err) => {
        console.error("API fetch error:", err);
        if (isMounted) {
          setOptions([]);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [inputValue, apiFetch]);

  // --- Close dropdown if clicked outside ---
  useEffect(() => {
    function handleClickOutside(e) {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // --- Handle input changes ---
  function handleChange(e) {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(true);
  }

  // --- Handle selecting an option from the dropdown ---
  function handleSelect(option) {
    // Store the entire option object in Formik, or just the ID/label if you prefer
    setFieldValue(name, option);
    setInputValue(option.label); // reflect chosen label in the input
    setIsOpen(false);
  }

  return (
    <Form.Group
      ref={containerRef}
      controlId={name}
      className="mb-3"
      style={{ position: "relative" }} // allows absolute positioning of dropdown
    >
      {/* {label && <Form.Label>{label}</Form.Label>} */}

      <InputGroup hasValidation>
        <Form.Control
          type="text"
          placeholder={placeholder || "Type to search..."}
          value={inputValue}
          onChange={handleChange}
          onFocus={() => setIsOpen(true)}
          isInvalid={meta.touched && !!meta.error}
        />
        {meta?.error && (
          <Form.Control.Feedback type="invalid" style={{ fontSize: "12px" }}>
            {meta.error}
          </Form.Control.Feedback>
        )}
      </InputGroup>

      {/* Dropdown list */}
      {isOpen && options.length > 0 && (
        <ListGroup
          style={{
            position: "absolute",
            zIndex: 1000,
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
          }}
          className={styles.searchableSelectList}
        >
          {options.map((option) => (
            <ListGroup.Item
              key={option.id}
              action
              onClick={() => handleSelect(option)}
            >
              {option.label}
              <br />
              <span style={{ fontSize: "14px" }}>{option?.address}</span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Form.Group>
  );
}

export default SearchableSelect;
